import Image from "next/image";
import { ReactElement } from "react";
import style from "./style.module.scss";

export default function Lawsuits(): ReactElement {
    return (
        <div className={style.wrapper}>
            <div className="md:w-10/12">
                <h2>What Do We Know About Tepezza?</h2>
                <span className={`${style["spanStyle"]}`}></span>
                <p>
                    Tepezza is used for thyroid eye disease (TED) to reduce
                    symptoms. It is a prescription drug used to treat TED in
                    adults. The immune system targets the adipose and muscular
                    tissue behind the eyes in cases of TED. Double vision, eye
                    discomfort, and eye redness may all result from this. Other
                    names for TED include Graves' ophthalmopathy, Graves'
                    orbitopathy, and Graves' eye illness.
                </p>
            </div>
            <div className="relative md:ml-10 w-[248px] h-[216px] mb-10 md:mb-0">
                <Image
                    src="/tepezza/assets/images/tepezzaBg.jpeg"
                    alt="tepezza"
                    layout="fill"
                />
            </div>
        </div>
    );
}
